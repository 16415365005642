/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #1e1e2f;
  color: #e0e0e0;
  font-family: 'Arial', sans-serif;
  overflow-x: hidden;
}

.rounded-full {
  transition: transform 0.2s ease-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(21, 76, 234, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 20px rgba(21, 76, 234, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(21, 76, 234, 0.7);
  }
}

.pulse-button {
  width: 80%;
  margin: auto;
  animation: pulse 2s infinite;
  cursor: pointer;
  margin-top: var(--nextui-space-7);
  margin-bottom: var(--nextui-space-12);
}
